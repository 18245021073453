import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
// import Cookies from "js-cookie";

const interceptorsHandller = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.VUE_APP_HOST_URL;
      // config.headers['Authorization'] = Cookies.get('wsm-token') || Cookies.get('token');
      // config.headers['device-uid'] = Cookies.get('uid') || '11322';
      // config.headers['device-id'] = Cookies.get('device-id') || '1';
      // config.headers['app-ver'] = Cookies.get('app-ver');
      // @ts-ignore
      // config.headers["platform"] = 3;
      return config;
    },
    (err) => {
      // Toast(err);
      return Promise.reject(err);
    }
  );

  instance.interceptors.response.use(
    (res: AxiosResponse<Phoenix.Common.AppResponse>) => {
      const { data, config } = res;

      if (data.code === undefined) {
        return { data };
      }

      if (data.code !== 200) {
        // Toast(data.message!);
        return Promise.reject();
      }
      return data;
    },
    (err) => {
      const {
        data: { msg },
        status,
      } = err.response;
      if (status !== 500) {
        console.log(err.response);
        // Toast(msg || JSON.stringify(err.message));
      }

      return Promise.reject(err);
    }
  );
  return instance;
};

interface IRequest {
  <T = any, R = Phoenix.Common.AppResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R>;
  <T = any, R = Phoenix.Common.AppResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
  request: <T = any>(
    config: AxiosRequestConfig
  ) => Promise<Phoenix.Common.AppResponse<T>>;
  get: <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ) => Promise<Phoenix.Common.AppResponse<T>>;
  post: <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<Phoenix.Common.AppResponse<T>>;
  put: <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<Phoenix.Common.AppResponse<T>>;
  delete: <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ) => Promise<Phoenix.Common.AppResponse<T>>;
  patch: <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<Phoenix.Common.AppResponse<T>>;
}

export const request = axios;

export default interceptorsHandller(axios.create()) as IRequest;
