import request from "@/utils/request";

export const getAnnouncement = (id: number | string) =>
  request.get<{ content: string; title: string }>(
    `/app/woshimi_app/app/notice?id=${id}`
  );

export const getGoods = () =>
  request.post<Phoenix.Common.GoodModel>(
    `/mall/woshimi_app/web/recharge/goods`,
    { platform: 3 }
  );

export const getUserById = (id: string) =>
  request.post<Phoenix.Common.UserInfo>(`/app/woshimi_app/user/id`, { id });

export const recharge = (data: Phoenix.Common.RechargeModel) =>
  request.post<{ page: string }>(`/mall/woshimi_app/web/front/pay`, data);
